import styled from 'styled-components';
import AppColors from '../../../styles/colors';
export const HeaderH2 = styled.h2`
  position: relative;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  padding: 0px 0px 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  width: 70%;
  float: right;
  color: rgb(31, 178, 90);
  margin-top: 0px !important;
  @media (max-width: 1399px) {
    margin-top: 0px !important;
  }
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 25px;
    line-height: 27px;
  }
`;

export const HeaderH6 = styled.h6`
  font-family: 'Gotham-Regular', arial, sans-serif !important;
  font-size: 17px !important;
  line-height: 32px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  color: rgba(0, 0, 0, 0.8) !important;
  width: 70%;
  float: right;
  @media (max-width: 767px) {
    font-size: 15px !important;
    line-height: 23px !important;
  }
`;
export const HeaderH3 = styled.h3`
  position: relative;
  margin: 0px 0px 40px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: rgb(0, 105, 170);
  text-align: left;
  float: right;
  width: 70%;
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 0px 0px 30px;
    font-size: 20px;
    line-height: 22px;
  }
`;
export const HeadingH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const ParaText = styled.p`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 24px;
  line-height: 38px;
  width: 70%;
  float: right;
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
  
    font-size: 19px !important;
    line-height: 27px !important;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const OrderedList = styled.ol`
  margin: 0 0 30px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: standard-counter;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 10px 20px 6px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
  }
  :hover {
    top: -5px;
    color: #55aa4c;
    border-color: #55aa4c;
    box-shadow: 0 5px 0 0 #55aa4c;
  }
`;
export const BlockQuotes = styled.blockquote`
  position: relative;
  width: 70%;
  margin: 0 0 80px 0;
  float: right;
  padding: 40px;
  margin: 0px 0px 40px;
  background: rgba(0, 0, 0, 0.05);
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px 0px 40px;
  }
  @media (max-width: 767px) {
    padding: 30px;
    margin: 0 0 30px 0;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 18px 0 0px;
    border-color: ${AppColors.backgroundBlack} transparent transparent
      transparent;
  }
  p {
    font-family: Gotham-Light, arial, sans-serif;
    font-size: 24px;
    line-height: 38px;
    width: 100%;
    @media (max-width: 1399px) {
      margin-bottom: 30px;
    }
    i {
      font-family: 'Gotham-Regular', arial, sans-serif !important;
      font-size: 17px !important;
      line-height: 32px !important;
      color: rgba(0, 0, 0, 0.8) !important;
      width: 100%;
      @media (max-width: 1399px) {
        font-size: 15px;
        line-height: 22px;
      }
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
  h3 {
    width: 100%;
    position: relative;
    margin-bottom: 0px;
    font-family: interstate, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 35px;
    line-height: 40px;
    color: rgb(0, 105, 170);
    text-align: left;
    @media (max-width: 767px) {
      margin: 0px 0px 30px;
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

export const ImageStyle = styled.img`
  float: left;
  width: 25%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const BlockQuotesLinkStyle = styled.blockquote`
  position: relative;
  margin: 0 0 0 0;
  padding: 0 0 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  margin-top: 0 !important;
  color: ${AppColors.LightGreen};
  &.lessMargin {
    margin-bottom: 15px;
  }
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    transition: all 0.2s ease-out;
    background: ${AppColors.backgroundBlack1};
  }
  @media (max-width: 1399px) {
    margin-top: 0 !important;
  }
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 25px;
    line-height: 27px;
  }
  :hover {
    color: #55aa4c;
  }
`;
